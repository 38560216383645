import { useEffect, useState, useContext } from "react";
import { gapi } from "gapi-script";
import { isUserAuthorized } from "../../utility/oAuth";
import { UserContext } from "../../App";
import { userListApi } from "../../utility/userListApi";
// import jwt from "jsonwebtoken";

function LogIn({
  setOwnerName,
  setOwnerId,
  welcome,
  setLoginname,
  setProfilePic,
  setUserRole,
  setFinanceDashboard,
  setSalesDashboard
}) {
  const [resultAuth, setRes] = useState("");
  const { ownerName, ownerId, loginName, profilePic } = useContext(UserContext);
  const clientId =
    "637346937058-1uatlp7jdlti8pkif734l5n5a3qt6mpm.apps.googleusercontent.com"; // production id
      // "833282319-tq6s9ajjann46d6oodtntpiu0rjk7ol3.apps.googleusercontent.com"; // demo auth id
      // "141767609515-e330r4jlc2kligciiq78368ut6up6mah.apps.googleusercontent.com"; // local auth id
  const users = [
    "vaishnavi@divami.com",
    "saipriya@divami.com",
    "amulya@divami.com",
    "chandu@divami.com",
    // "swathi@divami.coms",
  ];
  let flag = 0;
  const [userDetails, setUserDetails] = useState();
  window.addEventListener("storage", (e) => {
    welcome(false);
  });
  let response, ownerList;
  async function authUser(email) {
    response = await isUserAuthorized(email);
    // setUserDetails(response);
  }
  async function getUsers() {
    ownerList = await userListApi();
  }

  const onSuccess = async (res) => {
    // for (let i = 0; i < users.length; i++) {
    //   if (users[i] === email) {
    //     if (email === "vaishnavi@divami.com") {
    //       setOwnerName("Naveen");
    //       setOwnerId(10);
    //     } else if (email === "saipriya@divami.com") {
    //       setOwnerName("SaiKamal");
    //       setOwnerId(11);
    //     } else if (email === "amulya@divami.com") {
    //       setOwnerName("Viswa");
    //       setOwnerId(0);
    //     }
    // flag = 1; //flag is set on valid user login
    // localStorage.setItem("email", email);
    // welcome(true);
    //   }
    // }
    await authUser(res.wt.cu);
    const users = [
      "saipriya@divami.com",
      "vaishnavi@divami.com",
      "naveen@divami.com",
      "leela@divami.com",
      "vedavyas@divami.com",
      "saii@divami.com",
    ];
    if (response.isAuthorized) {
      await getUsers();
      setLoginname(res.wt.rV);
      setUserRole(response.data.role);
      setSalesDashboard(response.data.home);
      setFinanceDashboard(response.data.home);
      // setOwnerId(response.data.userId);
      setProfilePic(res.wt.hK);

      // const tokenPayload = {
      //   userId: response.data.userId, // Include any other relevant information
      //   username: res.wt.cu,
      //   role: response.data.role,
      // };
      // const token = jwt.sign(tokenPayload, "your-secret-key", {
      //   expiresIn: "1h", // Token expiration time (adjust as needed)
      // });
      // res.json({ token });
      if (users.includes(res.wt.cu)) {
        setOwnerName("All Owners");
        setOwnerId(0);
      } else {
        setOwnerName(res.wt.rV);
        let id = 0,
          i;
        for (i = 0; i < ownerList.length; i++) {
          if (
            ownerList[i].name.toLowerCase() ===
            response.data.userName.toLowerCase()
          ) {
            id = ownerList[i].id;
            break;
          }
        }
        setOwnerId(id);
      }
      flag = 1; //flag is set on valid user login
      localStorage.setItem("user", JSON.stringify(res));
      welcome(true);
    } else {

      alert("unauthorized"); //alert message on unauthorized person login
      welcome(false);
    }
    // if (flag === 0) {
    //   console.log("failed to show");

    //   alert("unauthorized"); //alert message on unauthorized person login
    //   welcome(false);
    // }
  };

  const onFailure = (res) => {
    console.log("login fail", res);
  };

  useEffect(() => {
    // welcome(true);

    if (localStorage.getItem("user") === null) {
      gapi.load("auth2", () => {
        gapi.auth2
          .init({
            client_id: clientId,
            scope: "",
            // redirect_uri: "http://localhost:3000/dashboardstaging/",
          })
          .then(() => {
            gapi.auth2
              .getAuthInstance()
              .signIn({
                prompt: "select_account",
                // ux_mode: 'redirect',
              })
              .then((response) => {
                // localStorage.setItem("email",response.wt.cu)
                onSuccess(response);
                // welcome(true)
              })
              .catch((error) => {
                onFailure(error);
              });
          });
      });
    } else {
      onSuccess(JSON.parse(localStorage.getItem("user")));
      // welcome(true)
    }
  }, []);
}

export default LogIn;
