import { useEffect, useState, createContext } from "react";
import DashboardScreen from "./components/DashboardScreen/DashboardScreen";
import LogIn from "./components/LogIn/LogIn";
import { gapi } from "gapi-script";
import "./App.scss";
import SalesDashboard from "./components/SalesDashboard/SalesDashboard";

export const UserContext = createContext();

function App() {
  const [welcome, setWelcome] = useState(false);
  const [ownerName, setOwnerName] = useState("All Owners");
  const [ownerId, setOwnerId] = useState(0);
  const [loginName, setLoginname] = useState("");
  const [profilePic, setProfilePic] = useState("");
  const [userRole, setUserRole] = useState("");
  const [salesDashboard, setSalesDashboard] = useState("");
  const [financeDashboard, setFinanceDashboard] = useState();
  const clientId =
    "637346937058-1uatlp7jdlti8pkif734l5n5a3qt6mpm.apps.googleusercontent.com"; // production id
    // "833282319-tq6s9ajjann46d6oodtntpiu0rjk7ol3.apps.googleusercontent.com"; // demo auth id
    //  "141767609515-e330r4jlc2kligciiq78368ut6up6mah.apps.googleusercontent.com"; // local auth id

  useEffect(() => {
    //purpose
    function start() {
      gapi.client.init({
        clientId: clientId,
        scope: "",
      });
    }
    gapi.load("client:auth2", start);
  }, []);

  
  return (
    <UserContext.Provider
      value={{
        ownerName,
        ownerId,
        loginName,
        profilePic,
        userRole,
        salesDashboard,
        financeDashboard,
      }}
    >
      <div className="App">
        {welcome ? (
          <>
            <DashboardScreen
              welcome={setWelcome}
              salesDashboard={salesDashboard}
              financeDashboard={financeDashboard}
              userRole={userRole}
            />
          </>
        ) : (
          <LogIn
            welcome={setWelcome}
            setOwnerName={setOwnerName}
            setOwnerId={setOwnerId}
            setLoginname={setLoginname}
            setProfilePic={setProfilePic}
            setUserRole={setUserRole}
            setSalesDashboard={setSalesDashboard}
            setFinanceDashboard={setFinanceDashboard}
          ></LogIn>
        )}
        {/* <SalesDashboard /> */}
        {/* <TokenGenerator secretKey={secretKey}/> */}
      </div>
    </UserContext.Provider>
  );
}

export default App;
